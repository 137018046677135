import React from 'react';
import Link from "gatsby-link";

import styles from "./homeintro.module.scss";

const HomeIntro = ({ siteIntro }) => (
  <div className={styles.home}>
    <div className={styles.intro}>
      <h2 dangerouslySetInnerHTML={{__html: siteIntro }} />
    </div>

    <div className={styles.menu}>
      <div className={styles.menuContent}>
        <h2>Menus</h2>

        <Link
          to="/menu"
          className={styles.menulink}
        >
          Click here to view our menu
        </Link>
      </div>
    </div>

    <div className={styles.res}>
      <div className={styles.resContent}>
        <h2>Reservations</h2>

        <div className={styles.reservations}>
          <div className={styles.item}>
            <Link
              to="/booking"
              className={styles.logo}
            >
              Click here to book your reservation
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HomeIntro;
