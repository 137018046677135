import React from "react"
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import { graphql } from "gatsby"

import HomeIntro from '../components/homeintro'

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.desc} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.desc} />
      </Helmet>
      <HomeIntro siteIntro={data.site.siteMetadata.intro} />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        intro
      }
    }
  }
`
