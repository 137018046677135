import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./layout.module.scss"

import Header from './header';
import Footer from './footer';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title,
            desc
          }
        }
      }
    `
  }
    render={data => (
      <div
        style={{
          margin: '0 auto',
          padding: '0'
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          {children}
        </div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </div>
    )}
  />
)
