import React from "react";
import styles from "./footer.module.scss";

const year = new Date().getFullYear();

const Footer = ({ siteTitle }) => (

  <div className={styles.footer}>
    &copy; {year} {siteTitle}
  </div>
)

export default Footer;
