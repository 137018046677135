import React from "react";
import { StaticQuery, graphql } from "gatsby"
import Link from "gatsby-link";

import MainMenu from "./nav";
import logo from "../assets/img/logo.png";
import styles from "./header.module.scss";

export default ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title,
            desc
          }
        },
        allWordpressWpApiMenusMenusItems (
            filter: { name:{ eq: "Top Menu" }}
          ) {
          edges {
            node {
              id
              name
              items {
                wordpress_id
                order
                wordpress_parent
                title
                url
                attr
                target
                classes
                xfn
                description
                object_id
                object
                object_slug
                type
                type_label
              }
            }
          }
        }
      }
    `
  }
  render={data => (

    <div className={styles.header}>

      <Link
        to="/"
        className={styles.logo}
      >
        <img
          src={logo}
          alt={siteTitle}
         />
      </Link>

      <div className={styles.menu}>
        <MainMenu menu={data} />
      </div>
    </div>

  )}
/>
)
