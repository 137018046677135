import React, {Component} from "react"
import Link from "gatsby-link"

import styles from "./nav.module.scss";

class MainMenu extends Component {
    render() {

        const data = this.props.menu.allWordpressWpApiMenusMenusItems.edges[0].node.items
        
        return (
              <div>
              {data.map((item) =>
                <div className={styles.item}>
                  <Link
                    to={item.object_slug === `home` ? `/` : item.object_slug}
                    className={styles.logo}
                  >
                    {item.title}
                  </Link>
                </div>
              )}
              </div>
      )
    }
}

export default MainMenu
